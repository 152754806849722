// @ts-nocheck
import React, { useEffect} from "react";

import { useParams } from "react-router-dom";

const SingleSongPage = (props: any) => {
  const {owner,songname}=useParams()
  console.log("Hello")
  useEffect(()=>{
    window.location.href = `https://play.leerecs.com/a/${owner}/s/${songname}`;
  })
  return (
    <>
    </>
  );
};

export default SingleSongPage;
