// @ts-nocheck
import React, { useEffect} from "react";
import { useParams } from "react-router-dom";


const ArtistProfile = (props: any) => {
  const {username}=useParams()
  console.log("hhhhhhhhhhhhhhhhh")
  useEffect(()=>{
    window.location.href = `https://play.leerecs.com/a/${username}`;
  },[])

  return (
    <>
    </>
  );
};

export default ArtistProfile;
