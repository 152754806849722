// @ts-nocheck
import React,{useState,useEffect} from "react";

//images
// import MainImage from "./src/MainImage";
import MainImage from "./assets/NoPath.png";
import headphonesIcon from "./assets/headphones_icon.svg";
import Volume from "./assets/volume.svg";
import File from "./assets/file.svg";

import { Link } from "react-router-dom"

import "./index.scss";

import { useAppDispatch } from "../../../../redux/hooks";
import {redirectElement } from "../../../../redux/data/redirectToProduction/index"
import {useHistory} from "react-router-dom"

const StartCareer = ({info}) => {
  
  const dispatch = useAppDispatch()
  const history=useHistory()
  return (
    <div className="container-fluid" id="music-production">
      <div className="row">
        <div className="col-md-6">
          <div className="main-title">
            {info && info.production.mainTitle}
          </div>
          <div className="services h3">
          {info && info.production.services}
          </div>
          <div className="catch-flase h4">
          {info && info.production.catchFalse}
          </div>
          <div className="text h6" style={{fontSize:"1.2em"}}>
             <span className="learn-more">
             {info && info.production.text}
              <a href="production#landing-production">{info && info.production.more}</a>
            </span>
          </div>
          <div className="d-flex btn-list">
            <div onClick={(e)=>{
              e.preventDefault()
              history.push("/production")
              dispatch(redirectElement(true))
            }}>
              <div className="btn btn-white">
                <div className="d-flex">
                  <img src={Volume} alt="" />
                  <div className="h5">Hear Examples</div>
                </div>
              </div>
            </div>
            <a href="https://play.leerecs.com/request">
              <div className="btn btn-white">
                <div className="d-flex">
                  <img src={File} alt="" />
                  <div className="h5 text-capitalize">Get a proposal</div>
                </div>
              </div>
            </a>
          </div>
        </div>
        <div className="col-md-6 production-image" style={{ backgroundImage:`url("/assets/images/guitor.png")`}}>
          <div className="title-text">
            Music
          </div>
        </div>
      </div>
    </div>
  );
};

export default StartCareer;
